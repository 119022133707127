<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Nome" label-for="profile-name">
            <b-form-input
              id="name"
              v-model="name"
              :class="{ 'is-invalid': v$.name.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.name.required.$invalid">
                Você deve informar um nome
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="9">
          <b-form-group
            label="Descrição"
            label-for="profile-description"
          >
            <b-form-input
              id="Descrição"
              v-model="description"
              :class="{ 'is-invalid': v$.description.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.description.required.$invalid">
                Você deve informar uma descrição para o perfil
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Departamento" label-for="profile-department">
            <v-select
              id="profile-department"
              v-model="department"
              :reduce="department => department.id"
              :options="departments"
              :disabled="!hasManageRole"
              label="name"
              dir="ltr"
              :class="getSelectErrorClass(v$.department.$error)"
              @input="getHasAdminProfile"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" v-if="hasManageRole">
          <b-form-group
            label="Perfil admin?"
            label-for="is-profile-admin"
          >
            <v-select
              id="is-profile-admin"
              :disabled="!department || departmentHasAdmin"
              v-model="isAdmin"
              :options="assertBooleanOptions"
              :reduce="(options) => options.value"
              label="name"
              :class="getSelectErrorClass(v$.isAdmin.$error)"
            />
            <div class="invalid-feedback">
              <span v-if="v$.isAdmin.required.$invalid">
                Você deve informar se o perfil será ou não admin
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" >
          <b-form-group
            label="Ativo?"
            label-for="is-profile-active"
          >
            <v-select
              id="is-profile-active"
              v-model="isActive"
              :options="assertBooleanOptions"
              :reduce="(options) => options.value"
              label="name"
              :class="getSelectErrorClass(v$.isActive.$error)"
            />
            <div class="invalid-feedback">
              <span v-if="v$.isActive.required.$invalid">
                Você deve informar se o perfil está ou não ativo
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <br>
          <h2>Permissões</h2>
          <template v-for="group in groups" >
            <div class="m-2" :key="`grou-ability-${group.id}`">
              <span class="card-title">{{ group.name }} </span>  
              <feather-icon
                size="21"
                :icon="`${groupSelected.includes(group.id) ? 'ChevronDown' : 'ChevronUp'}Icon`"
                @click="toggleGroup(group.id)"
              />
              <table class="table b-table table-sm table-striped " :hidden="!groupSelected.includes(group.id)">
                <tr style="border-top: hidden;">
                  <th></th>
                  <th>Admin</th>
                  <th>Leitura</th>
                  <th>Criação</th>
                  <th>Edição</th>
                  <th>Remoção</th>
                </tr>
                <template v-for="resource in resourcesByGroup(group)">
                  <tr>
                    <td class="w-25">{{ resource }}</td>
                    <td>
                      <b-form-checkbox
                        v-if="isAbilityAvailable(resource, 'manage')"
                        :checked="isAbilityEnabled(resource, 'manage')"
                        :disabled="saving"
                        @input="toggleManageAbility(resource, $event)"
                      />
                    </td>
                    <td>
                      <b-form-checkbox
                        v-if="isAbilityAvailable(resource, 'read')"
                        :checked="isAbilityEnabled(resource, 'read')"
                        :disabled="saving || manageSelected(resource)"
                        @input="toggleAbility(resource, 'read', $event)"
                      />
                    </td>
                    <td>
                      <b-form-checkbox
                        v-if="isAbilityAvailable(resource, 'create')"
                        :checked="isAbilityEnabled(resource, 'create')"
                        :disabled="saving || manageSelected(resource)"
                        @input="toggleAbility(resource, 'create', $event)"
                      />
                    </td>
                    <td>
                      <b-form-checkbox
                        v-if="isAbilityAvailable(resource, 'update')"
                        :checked="isAbilityEnabled(resource, 'update')"
                        :disabled="saving || manageSelected(resource)"
                        @input="toggleAbility(resource, 'update', $event)"
                      />
                    </td>
                    <td>
                      <b-form-checkbox
                        v-if="isAbilityAvailable(resource, 'delete')"
                        :checked="isAbilityEnabled(resource, 'delete')"
                        :disabled="saving || manageSelected(resource)"
                        @input="toggleAbility(resource, 'delete', $event)"
                      />
                    </td>
                  </tr>
                </template>
              </table>
              <br>
            </div>
          </template>
        </b-col>
        <b-col cols="12">
          <b-row class="justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'access-profiles-list' }"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mx-1"
              @click.prevent="save"
            >
              <b-spinner v-if="saving" small />
              <feather-icon v-else icon="SaveIcon" class="mr-50" />
              <span>
                {{ saving ? "Salvando..." : "Salvar" }}
              </span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import _ from 'lodash'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getVueSelectErrorClass } from '@/helpers/validators'
import { assertBooleanOptions } from '@/constants/assertions'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'
import { MANAGE_ACTION, PROFILE } from '@/constants/resources'
import { USER } from '@/modules/account/store/types'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() }
  },
  data() {
    return {
      assertBooleanOptions,
      loading: false,
      saving: false,
      name: undefined,
      description: undefined,
      isAdmin: false,
      selectedAbilities: [],
      groupSelected: [],
      departmentHasAdmin: false,
      isActive: true,
      department: undefined
    }
  },
  validations() {
    return {
      name: { required },
      department: { },
      isAdmin: { required },
      selectedAbilities:{ required },
      description: { required },
      isActive: { required }
    }
  },
  computed: {
    ...mapGetters({
      abilities: types.ABILITIES,
      departments: sharedTypes.DEPARTMENTS,
      user: USER
    }),
    groups() {
      return _(this.abilities)
        .map('group')
        .uniqBy('id')
        .compact()
        .value()
    },
    hasManageRole() {
      return this.$can(MANAGE_ACTION, PROFILE)
    },
    userDepartment() {
      return this.user ? this.user.department_id : undefined
    }
  },
  mounted() {
    if(!this.hasManageRole){
      this.department = this.userDepartment
    }
    this.getAbilities()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar as permissões para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.getDepartments()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar a lista de departamentos para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    if (this.$route.params.id) {
      this.loading = true
      this.getProfile(this.$route.params.id)
        .then(response => {
          const { 
            name,
            department_id,
            description,
            role_management_type,
            abilities
          } = response.data
          this.name = name
          this.department = department_id
          this.description = description
          this.isAdmin = role_management_type === 'department'
          this.selectedAbilities = abilities
          this.toogleGroupsByAbilities(abilities)
          this.v$.$touch()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar o perfil selecionado. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    ...mapActions({
      saveProfile: types.SAVE_ACCESS_PROFILE,
      getProfile: types.GET_ACCESS_PROFILE,
      getAbilities: types.GET_ABILITIES,
      getDepartments: sharedTypes.GET_DEPARTMENTS,
      hasAdminDepartment: types.HAS_ADMIN_DEPARTMENT
    }),
    resourcesByGroup(group) {
      return _(this.abilities)
        .filter({ group_id: group.id })
        .map('resource')
        .uniq()
        .value()
    },
    abilitiesByResource(resource) {
      return _(this.abilities)
        .filter({ resource })
        .value()
    },
    getAbilityByResourceAndPermission(resource, permission) {
      return _.find(this.abilities, function(obj) {
        return obj.resource === resource && obj.key.includes(permission)
      })
    },
    isAbilityEnabled(resource, permission) {
      const ability = this.getAbilityByResourceAndPermission(resource, permission)
      if(ability){
        return this.selectedAbilities.includes(ability.id)
      }
      return false
    },
    toggleAbility(resource, permission, value) {
      const ability = this.getAbilityByResourceAndPermission(resource, permission)
      if(ability){
        if (value) {
          this.selectedAbilities.push(ability.id)
        } else {
          this.selectedAbilities = _.without(this.selectedAbilities, ability.id)
        }
      }
    },
    toggleManageAbility(resource, value) {
      this.toggleAbility(resource, 'manage', value)
      const resourceAbilities = _.map(this.abilitiesByResource(resource), 'id')
      if (value) {
        this.selectedAbilities.push(...resourceAbilities)
      } else {
        this.selectedAbilities = _.without(this.selectedAbilities, ...resourceAbilities)
      }
    },
    manageSelected(resource) {
      const manageAbility = this.getAbilityByResourceAndPermission(resource, 'manage')
      if(manageAbility){
        return this.selectedAbilities.includes(manageAbility.id)
      }
      return false
    },
    toggleGroup(id) {
      if(this.groupSelected.includes(id)){
        this.groupSelected = _.without(this.groupSelected, id)
      }else{
        this.groupSelected.push(id)
      }
    },
    toogleGroupsByAbilities(abilitiesArray) {
        const abilities = _.filter(this.abilities, function(obj) {
          return abilitiesArray.includes(obj.id)
        }) 
        const groups = _(abilities)
          .map('group')
          .uniqBy('id')
          .compact()
          .value()

        this.groupSelected.push(..._.map(groups, 'id'))
    },
    getHasAdminProfile(){
      if(this.hasManageRole){
        this.hasAdminDepartment(this.department)
          .then(response => {
            if(response.data){
              this.departmentHasAdmin = response.data.hasAdmin
            }
          })
          .catch(error => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Oops!',
                text: 'Ocorreu um erro ao verificar se o departamento tem permissão de administrador. Entre em contato com o setor de TI.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    isAbilityAvailable(resource, permission) {
      const ability = this.getAbilityByResourceAndPermission(resource, permission)
      if(ability){
        return true
      }
      return false
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.saving = true
      const { 
        name, 
        department,
        description, 
        isAdmin, 
        selectedAbilities,
        isActive
      } = this
    
      let payload = {
        id: this.$route.params.id,
        name, 
        department_id: department,
        description, 
        is_admin: isAdmin, 
        abilities: _.uniq(selectedAbilities),
        is_active: isActive
      }
      this.saveProfile(payload)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: `O perfil foi ${
                this.$route.params.id ? 'atualizado' : 'criado'
              } com sucesso`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'access-profiles-list' })
        })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: error?.response?.data?.message || 'Ocorreu um erro ao salvar o perfil. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError)
    },
  },
}
</script>
